import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"

const treatTitle = str => {
  const title = str.split(' ')
  return <>{title.shift()}<span>{title.join(' ')}</span></>
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Site {
      allContentfulSite {
        edges {
          node {
            email
            address
            phone
            title
          }
        }
      }
      allContentfulHeroText {
        edges {
          node {
            detail {
              detail
            }
          }
        }
      }
  }`)
  const [navOpen, toggleNavOpen] = useState(false)
  const toggleNav = () => {
    toggleNavOpen(!navOpen)
  }
  const site = data.allContentfulSite.edges.map(({ node }) => ({ ...node }))[0]
  const hero = data.allContentfulHeroText.edges.map(({ node }) => ({ ...node }))[0]
  return (
    <>
      <nav class="navbar navbar-ct-success" id="demo-navbar">
        <div class="container">
          <div class="navbar-header">
            <button type="button" onClick={toggleNav}class="navbar-toggle" data-toggle="collapse" data-target="#navigation-example-2">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand brand" href="/">{treatTitle(site.title)}</a>
          </div>
      
          <div class={`${!navOpen ? 'collapse' : ''} navbar-collapse`} id="navigation-example-2">
            <ul class="nav navbar-nav navbar-right">
              <li>
                  <a href="#services" class="btn btn-simple">Services</a>
              </li>
              <li>
                  <a href="#projects" class="btn btn-simple">Projects</a>
              </li>
              <li>
                  <a href="#contact" class="btn btn-simple">Contact Us</a>
              </li>
              {/* <li>
                  <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                  <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-facebook"></i></a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>        
      {/* <div class="alert landing-alert alert-info">
        <div class="container text-center">
             <h5>Notification here</h5>
        </div>
      </div> */}
    
      <div class="wrapper">
        
        <div class="landing-header">
          <div class="container">
              <div class="motto">
                  <h1 class="title-uppercase brand">{treatTitle(site.title)}</h1>
                  <p hero-intro>{hero.detail.detail}</p>
                  <br />
                  <a class="btn btn-success btn-fill" href="#projects">See our work</a>
              </div>
          </div>    
        </div>

        <main class="main">
          {children}
        </main>

      </div>

      <footer class="footer-demo section-dark">
        <div class="container">
            <nav class="pull-left">
                <ul>
                    <li>
                      <a href="#services">Services</a>
                    </li>
                    <li>
                        <a href="#projects">Projects</a>
                    </li>
                    <li>
                        <a href="#contact">Contact Us</a>
                    </li>
                </ul>
            </nav>
            <div class="copyright pull-right">
                <span>&copy; 2020, {site.title}</span>
            </div>
        </div>
    </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
